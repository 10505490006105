import axios from 'axios';
import { BASE_URL } from '../../config';
import Cookies from 'js-cookie';

// const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';

const fetchUsersSuccess = (users) => ({
  type: FETCH_USERS_SUCCESS,
  payload: users,
});

export const fetchUsers = () => {
  return (dispatch) => {
    let token = Cookies.get('access_token');

    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${BASE_URL}v1/getAgents`,
      headers: { 
        'Authorization': `Bearer ${token}`
      }
    };
    
    axios.request(config)
    .then((response) => {
          const users = response.data.data;
        dispatch(fetchUsersSuccess(users));
    })
    .catch((error) => {
        console.error('Error fetching users:', error);
    });
  };
};

export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
