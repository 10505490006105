import {
  Uil500px,
  UilAirplay,
  UilArrowGrowth,
  UilAt,
  UilBagAlt,
  UilBookAlt,
  UilBookOpen,
  UilBookReader,
  UilCalendarAlt,
  UilChartBar,
  UilChat,
  UilCheckSquare,
  UilCircle,
  UilClipboardAlt,
  UilClock,
  UilCompactDisc,
  UilCreateDashboard,
  UilDatabase,
  UilDocumentLayoutLeft,
  UilEdit,
  UilEnvelope,
  UilExchange,
  UilExclamationOctagon,
  // UilExpandArrowsAlt,
  UilFile,
  UilFileShieldAlt,
  UilHeadphones,
  UilIcons,
  UilImages,
  UilLayerGroup,
  UilMap,
  UilPresentation,
  UilQuestionCircle,
  UilSearch,
  UilServer,
  UilSetting,
  UilShoppingCart,
  UilSquareFull,
  UilTable,
  UilUsdCircle,
  UilUsersAlt,
  UilMoneybag,
} from '@iconscout/react-unicons';
import { Input, Menu, message } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import UilEllipsisV from '@iconscout/react-unicons/icons/uil-ellipsis-v';
import propTypes from 'prop-types';
import { NavTitle } from './Style';
import versions from '../demoData/changelog.json';
import UilSignout from '@iconscout/react-unicons/icons/uil-signout';
import { Modal } from '../components/modals/antd-modals';
import Cookies, { set } from 'js-cookie';
import axios from 'axios';
import { BASE_URL } from '../config';

function MenuItems({ toggleCollapsed }) {
  const { t } = useTranslation();
  const [ownerState, setOwnerState] = useState({
    visible: false,
    modalType: 'primary',
    colorModal: false,
  });
  const [multiOwnerState, setMultiOwnerState] = useState({
    visible: false,
    modalType: 'primary',
    colorModal: false,
  });
  const [addIPState, setAddIPState] = useState({
    visible: false,
    modalType: 'primary',
    colorModal: false,
  });
  const [removeIPState, setRemoveIPState] = useState({
    visible: false,
    modalType: 'primary',
    colorModal: false,
  });
  const [addOwnerValue, setAddOwnerValue] = useState({
    name: '',
    email: '',
    mobile_number: '',
  });
  const [multiOwnerValue, setMultiOwnerValue] = useState('');
  const [addIPValue, setAddIPValue] = useState({
    agent_id: '',
    ip_address: '',
  });
  const [removeIPValue, setRemoveIPValue] = useState({
    agent_id: '',
    ip_address: '',
  });

  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }

  const { topMenu } = useSelector((state) => {
    return {
      topMenu: state.ChangeLayoutMode.topMenu,
    };
  });

  const path = '/admin';

  const pathName = window.location.pathname;
  const pathArray = pathName.split(path);
  const mainPath = pathArray[1];
  const mainPathSplit = mainPath.split('/');

  const [openKeys, setOpenKeys] = React.useState(
    !topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : [],
  );

  const onOpenChange = (keys) => {
    setOpenKeys(keys[keys.length - 1] !== 'recharts' ? [keys.length && keys[keys.length - 1]] : keys);
  };

  const onClick = (item) => {
    if (item.keyPath.length === 1) setOpenKeys([]);
  };

  const handleOwner = (id, agent_wallet) => {
    setOwnerState((prevState) => ({
      ...prevState,
      visible: true,
      modalType: 'primary',
    }));
  };

  const handleOwnerMultiple = (id, agent_wallet) => {
    setMultiOwnerState((prevState) => ({
      ...prevState,
      visible: true,
      modalType: 'primary',
    }));
  };

  const handleAddIP = (id, agent_wallet) => {
    setAddIPState((prevState) => ({
      ...prevState,
      visible: true,
      modalType: 'primary',
    }));
  };

  const handleRemoveIP = (id, agent_wallet) => {
    setRemoveIPState((prevState) => ({
      ...prevState,
      visible: true,
      modalType: 'primary',
    }));
  };

  const handleOwnerOk = () => {
    let token = Cookies.get('access_token');

    let data = JSON.stringify({
      name: addOwnerValue.name,
      email: addOwnerValue.email,
      mobile_number: addOwnerValue.mobile_number,
    });

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${BASE_URL}v1/owner/add`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        setOwnerState((prevState) => ({
          ...prevState,
          visible: false,
          colorModal: false,
        }));
        message.success(response.data.message);
        setAddOwnerValue({
          name: '',
          email: '',
          mobile_number: '',
        });
      })
      .catch((error) => {
        console.log(error);
        setOwnerState((prevState) => ({
          ...prevState,
          visible: false,
          colorModal: false,
        }));
        message.error(error.response.data.message);
        setAddOwnerValue({
          name: '',
          email: '',
          mobile_number: '',
        });
      });
  };

  const handleMultiOwnerOk = () => {
    let token = Cookies.get('access_token');

    let data = JSON.stringify({
      owners: [multiOwnerValue],
    });

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${BASE_URL}v1/authenticate-multiple-owners`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        setMultiOwnerState((prevState) => ({
          ...prevState,
          visible: false,
          colorModal: false,
        }));
        // setIsChanged(!isChanged);
        message.success(response.data.message);
        setMultiOwnerValue('');
      })
      .catch((error) => {
        console.log(error);
        setMultiOwnerState((prevState) => ({
          ...prevState,
          visible: false,
          colorModal: false,
        }));
        // setIsChanged(!isChanged);
        setMultiOwnerValue('');
      });
  };

  const handleAddIPOk = () => {
    let token = Cookies.get('access_token');

    let data = JSON.stringify({
      ip: addIPValue.ip_address,
    });

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${BASE_URL}v1/agent/whitelistip/${addIPValue.agent_id}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        setAddIPState((prevState) => ({
          ...prevState,
          visible: false,
          colorModal: false,
        }));
        message.success(response.data.message);
        setAddIPValue({
          agent_id: '',
          ip_address: '',
        });
      })
      .catch((error) => {
        console.log(error);
        setAddIPState((prevState) => ({
          ...prevState,
          visible: false,
          colorModal: false,
        }));
        message.error(error.response.data.message);
        setAddIPValue({
          agent_id: '',
          ip_address: '',
        });
      });
  };

  const handleRemoveIPOk = () => {
    let token = Cookies.get('access_token');

    let data = JSON.stringify({
      ip: removeIPValue.ip_address,
    });

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${BASE_URL}v1/agent/clearip/${removeIPValue.agent_id}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        setRemoveIPState((prevState) => ({
          ...prevState,
          visible: false,
          colorModal: false,
        }));
        message.success(response.data.message);
        setRemoveIPValue({
          agent_id: '',
          ip_address: '',
        });
      })
      .catch((error) => {
        console.log(error);
        setRemoveIPState((prevState) => ({
          ...prevState,
          visible: false,
          colorModal: false,
        }));
        message.error(error.response.data.message);
        setRemoveIPValue({
          agent_id: '',
          ip_address: '',
        });
      });
  };

  const handleCancel = () => {
    setOwnerState((prevState) => ({
      ...prevState,
      visible: false,
      colorModal: false,
    }));
    setMultiOwnerState((prevState) => ({
      ...prevState,
      visible: false,
      colorModal: false,
    }));
    setAddIPState((prevState) => ({
      ...prevState,
      visible: false,
      colorModal: false,
    }));
    setRemoveIPState((prevState) => ({
      ...prevState,
      visible: false,
      colorModal: false,
    }));
    // setIsChanged(!isChanged);
    // setCommissionValue('');
    // setAmountValue('');
  };

  const items = [
    getItem(
      <NavLink onClick={toggleCollapsed} to={path}>
        {t('dashboard')}
      </NavLink>,
      'dashboard',
      !topMenu && <UilCreateDashboard />,
    ),
    getItem(t('Users'), 'users', !topMenu && <UilUsersAlt />, [
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/customers/agents`}>
          {t('agents')}
        </NavLink>,
        'Agents',
        null,
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/customers/distibuters`}>
          {t('distibuters')}
        </NavLink>,
        'distibuters',
        null,
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/customers/api-users`}>
          {t('Api Users')}
        </NavLink>,
        'api users',
        null,
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/customers/distibuter-agents`}>
          {t('Distibuter Agents')}
        </NavLink>,
        'disitibuter agents',
        null,
      ),
    ]),
    getItem(t('Reports'), 'reports', !topMenu && <UilServer />, [
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/reports/transaction`}>
          {t('transaction')}
        </NavLink>,
        'transaction',
        null,
      ),
      //   getItem(
      //     <NavLink onClick={toggleCollapsed} to={`${path}/reports/new-transaction`}>
      //       {t('new transaction report')}
      //     </NavLink>,
      //     'new transaction report',
      //     null,
      //   ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/commission`}>
          {t('commission')}
        </NavLink>,
        'agents',
        null,
      ),
      // getItem(
      //   <NavLink onClick={toggleCollapsed} to={`${path}/commission/admin`}>
      //     {t('Admin')} {t('commission')}
      //   </NavLink>,
      //   'Admin',
      //   null,
      // ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/reports/agent-daily-data`}>
          {t('Daily')} {t('Report')}
        </NavLink>,
        'agents-daily-data',
        null,
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/wallet`}>
          {t('wallet ledger')}
        </NavLink>,
        'wallet ledger',
        null,
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/wallet/load`}>
          {t('wallet load')}
        </NavLink>,
        'wallet load',
        null,
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/reports/uppcl-txn-records`}>
          {t('Uppcl Records')}
        </NavLink>,
        'uppcl records',
        null,
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/forcefully-data`}>
          {t('Forcefully Data')}
        </NavLink>,
      ),
    ]),
    // getItem(t('Commissions'), 'Commissions', !topMenu && <UilTable />, [
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/commission/admin`}>
    //       {t('Admin')} {t('commission')}
    //     </NavLink>,
    //     'Admin',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/commission/agent`}>
    //       {t('Agents')} {t('commission')}
    //     </NavLink>,
    //     'agents',
    //     null,
    //   ),
    // ]),
    getItem(t('Pendings'), 'pendings', !topMenu && <UilCompactDisc />, [
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/pending-bills`}>
          {t('Pending Transaction')}
        </NavLink>,
        'Pending Transaction',
        null,
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/pending-wallet`}>
          {t('Pending Wallet')}
        </NavLink>,
        'Pending Wallet',
        null,
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/pending-owners`}>
          {t('pending owners')}
        </NavLink>,
        'pending owners',
        null,
      ),
    ]),
    // getItem(t('Bill Pay Reports'), 'bill pay', !topMenu && <UilServer />, [
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/reports/transaction`}>
    //       {t('transaction report')}
    //     </NavLink>,
    //     'transaction report',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/reports/new-transaction`}>
    //       {t('new transaction report')}
    //     </NavLink>,
    //     'new transaction report',
    //     null,
    //   ),
    // ]),
    getItem(t('Add'), 'add', !topMenu && <UilDatabase />, [
      getItem(<NavLink onClick={handleOwner}>{t('Add Owner')}</NavLink>, 'add owner', null),
      getItem(<NavLink onClick={handleAddIP}>{t('Add IP Address')}</NavLink>, 'add ip', null),
      getItem(<NavLink onClick={handleRemoveIP}>{t('Remove IP Address')}</NavLink>, 'remove ip', null),
    ]),
    getItem(t('Operations'), 'operations', !topMenu && <UilSetting />, [
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/forcefully-fail`}>
          {t('Mark Forcefully Fail')}
        </NavLink>,
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/refund`}>
          {t('Refund')}
        </NavLink>,
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/send-notification`}>
          {t('Send Notification')}
        </NavLink>,
      ),
    ]),
    getItem(t('Setting'), 'setting', !topMenu && <UilSetting />, [
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/setting`}>
          {t('Pending Receipt')}
        </NavLink>,
      ),
    ]),
    getItem(
      <NavLink className="text-red-500" onClick={toggleCollapsed} to={path}>
        {t('sign out')}
      </NavLink>,
      'sign out',
      !topMenu && <UilSignout className="text-red-500" />,
    ),
    // getItem(
    //   <NavLink onClick={toggleCollapsed} to={`${path}/main/chat/private/rofiq@gmail.com`}>
    //     {t('chat')}
    //   </NavLink>,
    //   'chat',
    //   !topMenu && (
    //     <NavLink className="menuItem-iocn" to={`${path}/main/chat/private/rofiq@gmail.com`}>
    //       <UilChat />
    //     </NavLink>
    //   ),
    // ),
    // getItem(t('eCommerce'), 'ecommerce', !topMenu && <UilShoppingCart />, [
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/ecommerce/products/grid`}>
    //       {t('products')}
    //     </NavLink>,
    //     'products',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/ecommerce/productDetails/1`}>
    //       {t('product')} {t('details')}
    //     </NavLink>,
    //     'productDetails',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/ecommerce/add-product`}>
    //       {t('product')} {t('add')}
    //     </NavLink>,
    //     'add-product',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/ecommerce/edit-product`}>
    //       {t('product')} {t('edit')}
    //     </NavLink>,
    //     'edit-product',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/ecommerce/cart`}>
    //       {t('cart')}
    //     </NavLink>,
    //     'cart',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/ecommerce/orders`}>
    //       {t('orders')}
    //     </NavLink>,
    //     'orsers',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/ecommerce/sellers`}>
    //       {t('sellers')}
    //     </NavLink>,
    //     'sellers',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/ecommerce/Invoice`}>
    //       {t('invoices')}
    //     </NavLink>,
    //     'Invoice',
    //     null,
    //   ),
    // ]),
    // getItem(`${t('social')} ${t('app')}`, 'profile', !topMenu && <Uil500px />, [
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/profile/myProfile/overview`}>
    //       {t('my')} {t('profile')}
    //     </NavLink>,
    //     'myProfile',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/profile/myProfile/timeline`}>
    //       {t('timeline')}
    //     </NavLink>,
    //     'profileTimeline',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/profile/myProfile/activity`}>
    //       {t('activity')}
    //     </NavLink>,
    //     'profileActivity',
    //     null,
    //   ),
    // ]),
    // getItem(t('project'), 'project', !topMenu && <UilBagAlt />, [
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/project/view/grid`}>
    //       {t('project')} {t('grid')}
    //     </NavLink>,
    //     'projectGrid',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/project/view/list`}>
    //       {t('project')} {t('list')}
    //     </NavLink>,
    //     'projectList',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/project/create`}>
    //       {t('create')} {t('project')}
    //     </NavLink>,
    //     'ProjectCreate',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/project/projectDetails/1/tasklist`}>
    //       {t('project')} {t('details')}
    //     </NavLink>,
    //     'projectDetails',
    //     null,
    //   ),
    // ]),
    // getItem(
    //   <NavLink onClick={toggleCollapsed} to={`${path}/app/calendar/month`}>
    //     {t('calendar')}
    //   </NavLink>,
    //   't-menu-calendar',
    //   !topMenu && (
    //     <NavLink className="menuItem-iocn" to={`${path}/app/calendar/month`}>
    //       <UilCalendarAlt />
    //     </NavLink>
    //   ),
    // ),
    // getItem(t('users'), 'users', !topMenu && <UilUsersAlt />, [
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/users/team`}>
    //       {t('team')}
    //     </NavLink>,
    //     'team',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/users/grid`}>
    //       {t('users')} {t('grid')}
    //     </NavLink>,
    //     'user-grid',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/users/list`}>
    //       {t('users')} {t('list')}
    //     </NavLink>,
    //     'user-list',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/users/grid-style`}>
    //       {t('users')} {t('grid')} {t('style')}
    //     </NavLink>,
    //     'grid-style',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/users/add-user/info`}>
    //       {t('add')} {t('user')}
    //     </NavLink>,
    //     'addUser',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/users/dataTable`}>
    //       {t('users')} {t('table')}
    //     </NavLink>,
    //     'user-dataTable',
    //     null,
    //   ),
    // ]),
    // getItem(t('contact'), 'contact', !topMenu && <UilAt />, [
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/contact/grid`}>
    //       {t('contact')} {t('grid')}
    //     </NavLink>,
    //     'contact-grid',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/contact/list`}>
    //       {t('contact')} {t('list')}
    //     </NavLink>,
    //     'contact-list',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/contact/addNew`}>
    //       {t('contact')} {t('create')}
    //     </NavLink>,
    //     'addNew',
    //     null,
    //   ),
    // ]),
    // getItem(
    //   <NavLink onClick={toggleCollapsed} to={`${path}/app/note/all`}>
    //     {t('note')}
    //   </NavLink>,
    //   'note',
    //   !topMenu && (
    //     <NavLink className="menuItem-iocn" to={`${path}/app/note/all`}>
    //       <UilClipboardAlt />
    //     </NavLink>
    //   ),
    // ),
    // getItem(
    //   <NavLink onClick={toggleCollapsed} to={`${path}/app/to-do`}>
    //     {t('to')} {t('do')}
    //   </NavLink>,
    //   'to-do',
    //   !topMenu && (
    //     <NavLink className="menuItem-iocn" to={`${path}/app/to-do`}>
    //       <UilCheckSquare />
    //     </NavLink>
    //   ),
    // ),
    // // getItem(
    // //   <NavLink onClick={toggleCollapsed} to={`${path}/app/kanban`}>
    // //     {t('kanban Board')}
    // //   </NavLink>,
    // //   'kanban',
    // //   !topMenu && <UilExpandArrowsAlt />,
    // // ),
    // getItem(`${t('import')} ${t('export')}`, 'importExport', !topMenu && <UilExchange />, [
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/importExport/import`}>
    //       {t('import')}
    //     </NavLink>,
    //     'import',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/importExport/export`}>
    //       {t('export')}
    //     </NavLink>,
    //     'export',
    //     null,
    //   ),
    // ]),
    // getItem(
    //   <NavLink onClick={toggleCollapsed} to={`${path}/app/task/all`}>
    //     {t('task')}
    //   </NavLink>,
    //   'task',
    //   !topMenu && <UilFile />,
    // ),
    // getItem(t('support'), 'supports', !topMenu && <UilHeadphones />, [
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/app/support/tickets`}>
    //       {t('support')}
    //     </NavLink>,
    //     'support',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/app/support/tickets/add`}>
    //       {t('add')} {t('support')}
    //     </NavLink>,
    //     'add-support',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/app/support/ticketDetails/01`}>
    //       {t('view')} {t('support')}
    //     </NavLink>,
    //     'view-support',
    //     null,
    //   ),
    // ]),
    // getItem(`${t('learning')} ${t('app')}`, 'course', !topMenu && <UilBookReader />, [
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/app/course`}>
    //       {t('courses')}
    //     </NavLink>,
    //     'course-list',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/app/course/courseDetails/1`}>
    //       {t('course')} {t('single')}
    //     </NavLink>,
    //     'single-course',
    //     null,
    //   ),
    // ]),
    // getItem(`${t('jobs')} ${t('search')}`, 'job-search', !topMenu && <UilHeadphones />, [
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/app/jobs/grid`}>
    //       {t('jobs')}
    //     </NavLink>,
    //     'jobs',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/app/jobDetails/1`}>
    //       {t('jobs')} {t('details')}
    //     </NavLink>,
    //     'job-details',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/app/job/apply`}>
    //       {t('job')} {t('apply')}
    //     </NavLink>,
    //     'job-apply',
    //     null,
    //   ),
    // ]),
    // getItem(
    //   !topMenu && (
    //     <NavTitle className="flex text-[12px] font-medium uppercase text-light mt-[20px] dark:text-white87 p-0">
    //       {t('crud')}
    //     </NavTitle>
    //   ),
    //   'CRUD-title',
    //   null,
    //   null,
    //   'group',
    // ),
    // getItem(t('axios'), 'axios', !topMenu && <UilDatabase />, [
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/axios/crud/axios-view`}>
    //       {t('view')} {t('all')}
    //     </NavLink>,
    //     'axios-view',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/axios/crud/add`}>
    //       {t('add')} {t('new')}
    //     </NavLink>,
    //     'axios-add',
    //     null,
    //   ),
    // ]),
    // getItem(
    //   !topMenu && (
    //     <NavTitle className="flex text-[12px] font-medium uppercase text-light mt-[20px] dark:text-white87 p-0">
    //       {t('features')}
    //     </NavTitle>
    //   ),
    //   'features-title',
    //   null,
    //   null,
    //   'group',
    // ),
    // getItem(t('ui elements'), 'components', !topMenu && <UilLayerGroup />, [
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/components/alerts`}>
    //       {t('alerts')}
    //     </NavLink>,
    //     'alerts',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/components/auto-complete`}>
    //       {t('autocomplete')}
    //     </NavLink>,
    //     'auto-complete',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/components/avatar`}>
    //       {t('avatar')}
    //     </NavLink>,
    //     'avatar',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/components/badge`}>
    //       {t('badge')}
    //     </NavLink>,
    //     'badge',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/components/breadcrumb`}>
    //       {t('breadcrumb')}
    //     </NavLink>,
    //     'breadcrumb',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/components/button`}>
    //       {t('button')}
    //     </NavLink>,
    //     'button',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/components/calendar`}>
    //       {t('calendar')}
    //     </NavLink>,
    //     'calendar',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/components/cards`}>
    //       {t('cards')}
    //     </NavLink>,
    //     'cards',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/components/carousel`}>
    //       {t('carousel')}
    //     </NavLink>,
    //     'carousel',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/components/cascader`}>
    //       {t('casecader')}
    //     </NavLink>,
    //     'cascader',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/components/checkbox`}>
    //       {t('checkbox')}
    //     </NavLink>,
    //     'checkbox',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/components/collapse`}>
    //       {t('collapse')}
    //     </NavLink>,
    //     'callapse',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/components/comments`}>
    //       {t('comments')}
    //     </NavLink>,
    //     'comments',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/components/base`}>
    //       {t('dashboard')} {t('base')}
    //     </NavLink>,
    //     'base',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/components/date-picker`}>
    //       {t('datepicker')}
    //     </NavLink>,
    //     'date-picker',
    //     null,
    //   ),
    //   getItem(<NavLink to="/admin/components/drag">Drag & Drop</NavLink>, 'drag', null),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/components/drawer`}>
    //       {t('drawer')}
    //     </NavLink>,
    //     'drawer',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/components/dropdown`}>
    //       {t('dropdown')}
    //     </NavLink>,
    //     'dropdown',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/components/empty`}>
    //       {t('empty')}
    //     </NavLink>,
    //     'empty',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/components/grid`}>
    //       {t('grid')}
    //     </NavLink>,
    //     '-dash-grid',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/components/input`}>
    //       {t('input')}
    //     </NavLink>,
    //     'input',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/components/list`}>
    //       {t('list')}
    //     </NavLink>,
    //     'dash-list',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/components/menu`}>
    //       {t('menu')}
    //     </NavLink>,
    //     'menu',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/components/message`}>
    //       {t('message')}
    //     </NavLink>,
    //     'message',
    //     null,
    //   ),
    // getItem(
    //   <NavLink onClick={toggleCollapsed} to={`${path}/components/modals`}>
    //     {t('modals')}
    //   </NavLink>,
    //   'modals',
    //   null,
    // ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/components/notification`}>
    //       {t('notification')}
    //     </NavLink>,
    //     'notifications',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/components/page-headers`}>
    //       {t('page')} {t('headers')}
    //     </NavLink>,
    //     'page-headers',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/components/pagination`}>
    //       {t('paginations')}
    //     </NavLink>,
    //     'paginations',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/components/confirm`}>
    //       {t('popconfirm')}
    //     </NavLink>,
    //     'popconfirme',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/components/popover`}>
    //       {t('popover')}
    //     </NavLink>,
    //     'popover',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/components/progress`}>
    //       {t('progress')}
    //     </NavLink>,
    //     'progress',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/components/radio`}>
    //       {t('radio')}
    //     </NavLink>,
    //     'radio',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/components/rate`}>
    //       {t('rate')}
    //     </NavLink>,
    //     'rate',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/components/result`}>
    //       {t('result')}
    //     </NavLink>,
    //     'result',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/components/select`}>
    //       {t('select')}
    //     </NavLink>,
    //     'select',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/components/skeleton`}>
    //       {t('skeleton')}
    //     </NavLink>,
    //     'skeleton',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/components/slider`}>
    //       {t('slider')}
    //     </NavLink>,
    //     'slider',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/components/spiner`}>
    //       {t('spinner')}
    //     </NavLink>,
    //     'spiner',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/components/statistic`}>
    //       {t('statistics')}
    //     </NavLink>,
    //     'statistics',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/components/steps`}>
    //       {t('steps')}
    //     </NavLink>,
    //     'steps',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/components/switch`}>
    //       {t('switch')}
    //     </NavLink>,
    //     'switch',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/components/tabs`}>
    //       {t('tabs')}
    //     </NavLink>,
    //     'tabs',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/components/tags`}>
    //       {t('tags')}
    //     </NavLink>,
    //     'tags',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/components/timeline`}>
    //       {t('timeline')}
    //     </NavLink>,
    //     'timeline',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/components/timepicker`}>
    //       {t('timepicker')}
    //     </NavLink>,
    //     'timepicker',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/components/tree-select`}>
    //       {t('treeselect')}
    //     </NavLink>,
    //     'treeselect',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/components/upload`}>
    //       {t('upload')}
    //     </NavLink>,
    //     'upload',
    //     null,
    //   ),
    // ]),
    // getItem(t('charts'), 'charts', !topMenu && <UilChartBar />, [
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/charts/chartjs`}>
    //       {t('chart')} {t('js')}
    //     </NavLink>,
    //     'chartjs',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/charts/google-chart`}>
    //       {t('google')} {t('chart')}
    //     </NavLink>,
    //     'google-chart',
    //     null,
    //   ),
    //   getItem(t('recharts'), 'recharts', !topMenu && <UilChartBar />, [
    //     getItem(
    //       <NavLink onClick={toggleCollapsed} to={`${path}/charts/recharts/bar`}>
    //         {t('bar')} {t('chart')}
    //       </NavLink>,
    //       'bar',
    //       null,
    //     ),
    //     getItem(
    //       <NavLink onClick={toggleCollapsed} to={`${path}/charts/recharts/area`}>
    //         {t('Area')} {t('chart')}
    //       </NavLink>,
    //       'area',
    //       null,
    //     ),
    //     getItem(
    //       <NavLink onClick={toggleCollapsed} to={`${path}/charts/recharts/composed`}>
    //         {t('Composed')} {t('chart')}
    //       </NavLink>,
    //       'composed',
    //       null,
    //     ),
    //     getItem(
    //       <NavLink onClick={toggleCollapsed} to={`${path}/charts/recharts/line`}>
    //         {t('Line')} {t('chart')}
    //       </NavLink>,
    //       'line',
    //       null,
    //     ),
    //     getItem(
    //       <NavLink onClick={toggleCollapsed} to={`${path}/charts/recharts/pie`}>
    //         {t('Pie')} {t('chart')}
    //       </NavLink>,
    //       'pie',
    //       null,
    //     ),
    //     getItem(
    //       <NavLink onClick={toggleCollapsed} to={`${path}/charts/recharts/radar`}>
    //         {t('radar')} {t('chart')}
    //       </NavLink>,
    //       'radar',
    //       null,
    //     ),
    //     getItem(
    //       <NavLink onClick={toggleCollapsed} to={`${path}/charts/recharts/radial`}>
    //         {t('radial')} {t('charts')}
    //       </NavLink>,
    //       'radial',
    //       null,
    //     ),
    //   ]),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/charts/peity`}>
    //       {t('peity')} {t('charts')}
    //     </NavLink>,
    //     'peity',
    //     null,
    //   ),
    // ]),
    // getItem(t('forms'), 'forms', !topMenu && <UilCompactDisc />, [
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/features/form-layout`}>
    //       {t('form')} {t('layouts')}
    //     </NavLink>,
    //     'form-layout',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/features/form-elements`}>
    //       {t('form')} {t('elements')}
    //     </NavLink>,
    //     'form-elements',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/features/form-components`}>
    //       {t('form')} {t('components')}
    //     </NavLink>,
    //     'form-components',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/features/form-validation`}>
    //       {t('form')} {t('validation')}
    //     </NavLink>,
    //     'form-validation',
    //     null,
    //   ),
    // ]),
    // getItem(t('table'), 'table', !topMenu && <UilTable />, [
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/tables/basic`}>
    //       {t('basic')} {t('table')}
    //     </NavLink>,
    //     'basicTable',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/tables/dataTable`}>
    //       {t('data')} {t('table')}
    //     </NavLink>,
    //     'dataTable',
    //     null,
    //   ),
    // ]),
    // getItem(t('widgets'), 'widgets', !topMenu && <UilServer />, [
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/widgets/chart`}>
    //       {t('chart')}
    //     </NavLink>,
    //     'chart',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/widgets/card`}>
    //       {t('card')}
    //     </NavLink>,
    //     'card',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/widgets/mixed`}>
    //       {t('mixed')}
    //     </NavLink>,
    //     'mixed',
    //     null,
    //   ),
    // ]),
    // getItem(t('Wizards'), 'wizards', !topMenu && <UilSquareFull />, [
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/pages/wizards/one`}>
    //       {t('Wizard')} {t('1')}
    //     </NavLink>,
    //     'wizard-one',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/pages/wizards/two`}>
    //       {t('Wizard')} {t('2')}
    //     </NavLink>,
    //     'wizard-two',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/pages/wizards/three`}>
    //       {t('Wizard')} {t('3')}
    //     </NavLink>,
    //     'wizard-three',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/pages/wizards/four`}>
    //       {t('Wizard')} {t('4')}
    //     </NavLink>,
    //     'wizard-four',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/pages/wizards/five`}>
    //       {t('Wizard')} {t('5')}
    //     </NavLink>,
    //     'wizard-five',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/pages/wizards/six`}>
    //       {t('Wizard')} {t('6')}
    //     </NavLink>,
    //     'wizard-six',
    //     null,
    //   ),
    // ]),
    // getItem(t('icons'), 'icons', !topMenu && <UilIcons />, [
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/icons/unicon`}>
    //       {t('unicon(svg)')}
    //     </NavLink>,
    //     'unicons',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/icons/font-awesome`}>
    //       {t('fontawesome')}
    //     </NavLink>,
    //     'font-awesome',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/icons/antd`}>
    //       {t('ant')} {t('design')} {t('icons')}
    //     </NavLink>,
    //     'antd',
    //     null,
    //   ),
    // ]),
    // getItem(
    //   <NavLink onClick={toggleCollapsed} to={`${path}/editor`}>
    //     {t('editors')}
    //   </NavLink>,
    //   'editor',
    //   !topMenu && (
    //     <NavLink className="menuItem-iocn" to={`${path}/editor`}>
    //       <UilEdit />
    //     </NavLink>
    //   ),
    // ),
    // getItem(t('maps'), 'maps', !topMenu && <UilMap />, [
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/maps/google`}>
    //       {t('google')} {t('maps')}
    //     </NavLink>,
    //     'google',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/maps/leaflet`}>
    //       {t('leaflet')} {t('map')}
    //     </NavLink>,
    //     'leaflet',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/maps/Vector`}>
    //       {t('simple')} {t('map')}
    //     </NavLink>,
    //     'vector',
    //     null,
    //   ),
    // ]),
    // getItem(
    //   !topMenu && (
    //     <NavTitle className="flex text-[12px] font-medium uppercase text-light mt-[20px] dark:text-white87 p-0">
    //       {t('Pages')}
    //     </NavTitle>
    //   ),
    //   'page-title',
    //   null,
    //   null,
    //   'group',
    // ),
    // getItem(
    //   <NavLink onClick={toggleCollapsed} to={`${path}/pages/settings`}>
    //     {t('settings')}
    //   </NavLink>,
    //   'settings',
    //   !topMenu && (
    //     <NavLink className="menuItem-iocn" to={`${path}/pages/settings`}>
    //       <UilSetting />
    //     </NavLink>
    //   ),
    // ),
    // getItem(t('gallery'), 'gallery', !topMenu && <UilImages />, [
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/gallery/one`}>
    //       {t('gallery')} {t('1')}
    //     </NavLink>,
    //     'galllery-one',
    //     null,
    //   ),
    // ]),
    // getItem(
    //   <NavLink onClick={toggleCollapsed} to={`${path}/pages/pricing`}>
    //     {t('pricing')}
    //   </NavLink>,
    //   'pricing',
    //   !topMenu && (
    //     <NavLink className="menuItem-iocn" to={`${path}/pages/pricing`}>
    //       <UilUsdCircle />
    //     </NavLink>
    //   ),
    // ),
    // getItem(
    //   <NavLink onClick={toggleCollapsed} to={`${path}/pages/banners`}>
    //     {t('banners')}
    //   </NavLink>,
    //   'banners',
    //   !topMenu && (
    //     <NavLink className="menuItem-iocn" to={`${path}/pages/banners`}>
    //       <UilPresentation />
    //     </NavLink>
    //   ),
    // ),
    // getItem(
    //   <NavLink onClick={toggleCollapsed} to={`${path}/pages/testimonials`}>
    //     {t('testimonials')}
    //   </NavLink>,
    //   'testimonials',
    //   !topMenu && (
    //     <NavLink className="menuItem-iocn" to={`${path}/pages/testimonials`}>
    //       <UilBookOpen />
    //     </NavLink>
    //   ),
    // ),
    // getItem(
    //   <NavLink onClick={toggleCollapsed} to={`${path}/pages/faq`}>
    //     {t('faqs')}
    //   </NavLink>,
    //   'faq',
    //   !topMenu && (
    //     <NavLink className="menuItem-iocn" to={`${path}/pages/faq`}>
    //       <UilQuestionCircle />
    //     </NavLink>
    //   ),
    // ),
    // getItem(
    //   <NavLink onClick={toggleCollapsed} to={`${path}/pages/search`}>
    //     {t('search')} {t('results')}
    //   </NavLink>,
    //   'search',
    //   !topMenu && (
    //     <NavLink className="menuItem-iocn" to={`${path}/pages/search`}>
    //       <UilSearch />
    //     </NavLink>
    //   ),
    // ),
    // getItem(
    //   <NavLink onClick={toggleCollapsed} to={`${path}/pages/starter`}>
    //     {t('blank')} {t('page')}
    //   </NavLink>,
    //   'starter',
    //   !topMenu && (
    //     <NavLink className="menuItem-iocn" to={`${path}/pages/starter`}>
    //       <UilCircle />
    //     </NavLink>
    //   ),
    // ),
    // getItem(t('Knowledgebase'), 'knowledgebase', !topMenu && <UilBookAlt />, [
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/knowledgebase/plugins`}>
    //       {t('knowledge')} {t('base')}
    //     </NavLink>,
    //     'plugins',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/all-articles`}>
    //       {t('all')} {t('article')}
    //     </NavLink>,
    //     'all-articles',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/knowledgebaseSingle/1`}>
    //       {t('single')} {t('article')}
    //     </NavLink>,
    //     'knowledgebaseSingle',
    //     null,
    //   ),
    // ]),
    // getItem(t('blog'), 'blog', !topMenu && <UilDocumentLayoutLeft />, [
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/pages/blog/blogone`}>
    //       {t('blog')} {t('one')}
    //     </NavLink>,
    //     'blog-one',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/pages/blog/blogtwo`}>
    //       {t('blog')} {t('two')}
    //     </NavLink>,
    //     'blog-2',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/pages/blog/blogthree`}>
    //       {t('blog')} {t('three')}
    //     </NavLink>,
    //     'blog-3',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/pages/blog/details`}>
    //       {t('blog')} {t('details')}
    //     </NavLink>,
    //     'blog-details',
    //     null,
    //   ),
    // ]),
    // getItem(
    //   <NavLink onClick={toggleCollapsed} to={`${path}/pages/maintenance`}>
    //     {t('maintanance')}
    //   </NavLink>,
    //   'maintenance',
    //   !topMenu && <UilAirplay />,
    // ),
    // getItem(
    //   <NavLink onClick={toggleCollapsed} to={`${path}/pages/404`}>
    //     {t('404')}
    //   </NavLink>,
    //   '404',
    //   !topMenu && (
    //     <NavLink className="menuItem-iocn" to={`${path}/pages/404`}>
    //       <UilExclamationOctagon />
    //     </NavLink>
    //   ),
    // ),
    // getItem(
    //   <NavLink onClick={toggleCollapsed} to={`${path}/pages/comingSoon`}>
    //     {t('coming')} {t('soon')}
    //   </NavLink>,
    //   'comingsoon',
    //   !topMenu && (
    //     <NavLink className="menuItem-iocn" to={`${path}/pages/comingSoon`}>
    //       <UilClock />
    //     </NavLink>
    //   ),
    // ),
    // getItem(
    //   <NavLink onClick={toggleCollapsed} to={`${path}/pages/termCondition`}>
    //     {t('terms')} {t('&')} {t('conditions')}
    //   </NavLink>,
    //   'termcondition',
    //   !topMenu && (
    //     <NavLink className="menuItem-iocn" to={`${path}/pages/termCondition`}>
    //       <UilFileShieldAlt />
    //     </NavLink>
    //   ),
    // ),
  ];

  return (
    <>
      <Modal type="primary" title="Add Owner" visible={ownerState.visible} onOk={handleOwnerOk} onCancel={handleCancel}>
        <div className="dark:text-white60">
          <Input
            className="mt-2"
            value={addOwnerValue.name}
            onChange={(e) => {
              let value = e.target.value;
              setAddOwnerValue({ ...addOwnerValue, name: value });
            }}
            placeholder="Name"
          />
          <Input
            type="email"
            className="mt-2"
            value={addOwnerValue.email}
            onChange={(e) => {
              let value = e.target.value;
              setAddOwnerValue({ ...addOwnerValue, email: value });
            }}
            placeholder="Email Address"
          />
          <Input
            type="tel"
            className="mt-2"
            value={addOwnerValue.mobile_number}
            onChange={(e) => {
              let value = e.target.value;
              setAddOwnerValue({ ...addOwnerValue, mobile_number: value });
            }}
            maxLength={10}
            placeholder="Mobile Number"
          />
        </div>
      </Modal>
      <Modal
        type="primary"
        title="Add Multiple Owner"
        visible={multiOwnerState.visible}
        onOk={handleMultiOwnerOk}
        onCancel={handleCancel}
      >
        <div className="dark:text-white60">
          <Input
            className="mt-2"
            value={multiOwnerValue}
            onChange={(e) => {
              let value = e.target.value;
              setMultiOwnerValue(value);
            }}
            placeholder="Enter Owner ID"
          />
        </div>
      </Modal>
      <Modal
        type="primary"
        title="Whitelist IP Address"
        visible={addIPState.visible}
        onOk={handleAddIPOk}
        onCancel={handleCancel}
      >
        <div className="dark:text-white60">
          <Input
            className="mt-2"
            value={addIPValue.agent_id}
            onChange={(e) => {
              let value = e.target.value;
              setAddIPValue({ ...addIPValue, agent_id: value });
            }}
            placeholder="Agent ID"
          />
          <Input
            className="mt-2"
            value={addIPValue.ip_address}
            onChange={(e) => {
              let value = e.target.value;
              setAddIPValue({ ...addIPValue, ip_address: value });
            }}
            placeholder="IP Address"
          />
        </div>
      </Modal>
      <Modal
        type="primary"
        title="Remove IP Address"
        visible={removeIPState.visible}
        onOk={handleRemoveIPOk}
        onCancel={handleCancel}
      >
        <div className="dark:text-white60">
          <Input
            className="mt-2"
            value={removeIPValue.agent_id}
            onChange={(e) => {
              let value = e.target.value;
              setRemoveIPValue({ ...removeIPValue, agent_id: value });
            }}
            placeholder="Agent ID"
          />
          <Input
            className="mt-2"
            value={removeIPValue.ip_address}
            onChange={(e) => {
              let value = e.target.value;
              setRemoveIPValue({ ...removeIPValue, ip_address: value });
            }}
            placeholder="IP Address"
          />
        </div>
      </Modal>
      <Menu
        onOpenChange={onOpenChange}
        onClick={onClick}
        mode={!topMenu || window.innerWidth <= 991 ? 'inline' : 'horizontal'}
        // // eslint-disable-next-line no-nested-ternary
        defaultSelectedKeys={
          !topMenu
            ? [
                `${
                  mainPathSplit.length === 1 ? 'home' : mainPathSplit.length === 2 ? mainPathSplit[1] : mainPathSplit[2]
                }`,
              ]
            : []
        }
        defaultOpenKeys={!topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : []}
        overflowedIndicator={<UilEllipsisV />}
        openKeys={openKeys}
        items={items}
      />
    </>
  );
}

MenuItems.propTypes = {
  toggleCollapsed: propTypes.func,
};

export default MenuItems;
