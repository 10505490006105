import Cookies from 'js-cookie';
import actions from './actions';
import axios from 'axios';
import { BASE_URL } from '../../config';

const { loginBegin, loginSuccess, loginErr, logoutBegin, logoutSuccess, logoutErr } = actions;

const login = (values, callback) => {
  return async (dispatch) => {
    dispatch(loginBegin());
    try {
      const response = await axios.post(`${BASE_URL}v1/owner`, values, {
        headers: { Accept: 'application/json' },
        withCredentials: true,
      });
      if (response.data.errors) {
        dispatch(loginErr(response.data.errors));
      } else {
        console.log(response.data);
        localStorage.setItem('token', response.data.message);
        Cookies.set('access_token', response.data.message);
        Cookies.set('logedIn', true);
        dispatch(loginSuccess(true));
        callback();
      }
    } catch (err) {
      dispatch(loginErr(err));
    }
  };
};

const logOut = (callback) => {
  return async (dispatch) => {
    dispatch(logoutBegin());
    try {
      Cookies.remove('logedIn');
      Cookies.remove('access_token');
      dispatch(logoutSuccess(false));
      callback();
    } catch (err) {
      dispatch(logoutErr(err));
    }
  };
};

export { login, logOut };
