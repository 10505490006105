// import initialState from '../../demoData/usersData.json';
// import initialStateGroup from '../../demoData/usersGroupData.json';

// const userReducer = (state = initialState) => {
//   return state;
// };

// const userGroupReducer = (state = initialStateGroup) => {
//   return state;
// };

// export { userReducer, userGroupReducer };

import initialStateGroup from '../../demoData/usersGroupData.json';
import { FETCH_USERS_SUCCESS } from './actions';

const initialState = {
  users: [],
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload,
      };
    default:
      return state;
  }
};

const userGroupReducer = (state = initialStateGroup) => {
  return state;
};

export { userReducer, userGroupReducer };
