// /* eslint-disable jsx-a11y/no-static-element-interactions */
// /* eslint-disable jsx-a11y/click-events-have-key-events */
// /* eslint-disable react/prop-types */
// import UilEllipsisV from '@iconscout/react-unicons/icons/uil-ellipsis-v';
// import { Button, Col, Layout, Row, Typography } from 'antd';
// import propTypes from 'prop-types';
// import { Component } from 'react';
// import { Scrollbars } from '@pezhmanparsaee/react-custom-scrollbars';
// import { connect } from 'react-redux';
// import { Link, NavLink } from 'react-router-dom';
// import { ThemeProvider } from 'styled-components';
// import MenueItems from './MenueItems';
// import CustomizerWrap from './overview/Customizer';
// import { FooterStyle, LayoutContainer, SmallScreenAuthInfo, TopMenuSearch } from './Style';
// import TopMenu from './TopMenu';
// import Search from '../components/utilities/auth-info/Search';
// import AuthInfo from '../components/utilities/auth-info/info';
// import { ReactComponent as MySVG } from '../static/img/icon/left-bar.svg';
// import logo from '../static/img/logo-3.png';
// import { UilRefresh } from '@iconscout/react-unicons';

// const { theme } = require('../config/theme/themeVariables');

// const { Header, Sider, Content } = Layout;

// const ThemeLayout = (WrappedComponent) => {
//   class LayoutComponent extends Component {
//     constructor(props) {
//       super(props);
//       this.state = {
//         collapsed: false,
//         hide: true,
//         walletBalance: 0,
//         reloadBalance: false,
//       };
//       this.updateDimensions = this.updateDimensions.bind(this);
//     }

//     handleWalletBalanceChange = (walletBalance) => {
//       this.setState({
//         walletBalance: walletBalance,
//       });
//     };

//     handleReloadbalance = (isReload) => {
//       this.setState((prevState) => ({
//         reloadBalance: !prevState.reloadBalance,
//       }));
//     };

//     componentDidMount() {
//       window.addEventListener('resize', this.updateDimensions);
//       this.updateDimensions();
//     }

//     componentWillUnmount() {
//       window.removeEventListener('resize', this.updateDimensions);
//     }

//     updateDimensions() {
//       this.setState({
//         collapsed: window.innerWidth <= 1200 && true,
//       });
//     }

//     render() {
//       const { collapsed, hide } = this.state;
//       const { layoutMode, rtl, topMenu } = this.props;

//       const left = !rtl ? 'left' : 'right';
//       const toggleCollapsed = () => {
//         this.setState({
//           collapsed: !collapsed,
//         });
//       };

//       const toggleCollapsedMobile = () => {
//         if (window.innerWidth <= 990) {
//           this.setState({
//             collapsed: !collapsed,
//           });
//         }
//       };

//       const onShowHide = () => {
//         this.setState({
//           hide: !hide,
//         });
//       };

//       const SideBarStyle = {
//         margin: '63px 0 0 0',
//         padding: `${!rtl ? '20px 20px 55px 0' : '20px 0 55px 20px'}`,
//         overflowY: 'auto',
//         height: '100vh',
//         position: 'fixed',
//         [left]: 0,
//         zIndex: 988,
//       };

//       function renderThumb({ style }) {
//         const thumbStyle = {
//           borderRadius: 6,
//           backgroundColor: '#F1F2F6',
//         };
//         return <div style={{ ...style, ...thumbStyle }} />;
//       }
//       const renderTrackVertical = () => {
//         const thumbStyle = {
//           position: 'absolute',
//           width: '6px',
//           transition: 'opacity 200ms ease 0s',
//           opacity: 0,
//           [rtl ? 'left' : 'right']: '0px',
//           bottom: '2px',
//           top: '2px',
//           borderRadius: '3px',
//         };
//         return <div className="[&>div]:bg-regular dark:[&>div]:bg-[#32333f]" style={thumbStyle} />;
//       };
//       function renderView({ style }) {
//         const customStyle = {
//           marginRight: rtl && 'auto',
//           [rtl ? 'marginLeft' : 'marginRight']: '-17px',
//         };
//         return <div style={{ ...style, ...customStyle }} />;
//       }
//       renderThumb.propTypes = {
//         style: propTypes.shape(propTypes.object),
//       };
//       renderView.propTypes = {
//         style: propTypes.shape(propTypes.object),
//       };

//       // const isMobile = window.innerWidth <= 768;

//       return (
//         <LayoutContainer>
//           <Layout className="layout">
//             <Header
//               style={{
//                 position: 'fixed',
//                 // width: isMobile ? '100%' : 'auto',
//                 width: '100%',
//                 top: 0,
//                 [!rtl ? 'left' : 'right']: 0,
//               }}
//               className="p-0 flex items-center justify-between bg-white dark:bg-[#1b1e2b] dark:shadow-[0_5px_20px_rgba(160,160,160,.02)] h-[72px] z-998"
//             >
//               <div className="flex flex-row items-center flex-1 h-full">
//                 <div className=" rtl:ssm:pr-[15px] ltr:pr-5 rtl:pl-5 ltr:ssm:pl-[15px] ltr:ssm:pr-[15px] rtl:ssm::pl:[15px] ltr:pl-[30px] rtl:pr-[30px] xs:ltr:pl-[20px] xs:rtl:pr-[20px] min-w-[280px] ssm:min-w-[220px] xs:min-w-[170px] h-full grid align-middle dark:bg-[#323541]">
//                   <div className="flex items-center">
//                     <Link to="/admin">
//                       <img className="w-full max-w-[60px] xs:max-w-[60px]" src={logo} alt="" />
//                     </Link>
//                     {!topMenu || window.innerWidth <= 991 ? (
//                       <Button
//                         type="link"
//                         className="p-0 bg-transparent ml-24 border-none dark:border-transparent dark:bg-transparent dark:text-white60 dark:hover:text-primary text-[#525768] hover:text-primary"
//                         onClick={toggleCollapsed}
//                       >
//                         <MySVG />
//                       </Button>
//                     ) : null}
//                   </div>
//                 </div>
//                 <div className="flex items-center justify-end flex-auto ltr:mr-[10px] rtl:ml-[10px] [&>div:first-child]:flex [&>div]:items-center md:flex">
//                   <div className="flex flex-row items-center md:hidden">
//                     {topMenu && window.innerWidth > 991 ? (
//                       <div className="flex top-right-wrap">
//                         {/* <span>Wallet Balance: {this.state.walletBalance}</span> */}
//                         <AuthInfo
//                           onWalletBalanceChange={this.handleWalletBalanceChange}
//                           reloadBalance={this.state.reloadBalance}
//                         />
//                       </div>
//                     ) : (
//                       <>
//                         <UilRefresh onClick={this.handleReloadbalance} className="h-4 w-4 mr-2 text-black" />
//                         <span onWalletBalanceUpdate={this.handleUpdateBalance} className="md:hidden flex">
//                           Wallet Balance: {this.state.walletBalance}Rs.
//                         </span>
//                         <AuthInfo
//                           onWalletBalanceChange={this.handleWalletBalanceChange}
//                           reloadBalance={this.state.reloadBalance}
//                         />
//                       </>
//                     )}
//                   </div>
//                 </div>
//               </div>
//             </Header>
//             <Row>
//               <Col md={0} sm={24} xs={24}>
//                 <SmallScreenAuthInfo hide={hide}>
//                   <AuthInfo rtl={rtl} />
//                 </SmallScreenAuthInfo>
//               </Col>
//             </Row>
//             <Layout>
//               {!topMenu || window.innerWidth <= 991 ? (
//                 <ThemeProvider theme={theme}>
//                   <Sider
//                     width={230}
//                     style={SideBarStyle}
//                     collapsed={collapsed}
//                     theme={layoutMode === 'lightMode' ? 'light' : 'dark'}
//                   >
//                     <Scrollbars
//                       className="custom-scrollbar"
//                       autoHide
//                       autoHideTimeout={500}
//                       autoHideDuration={200}
//                       // renderThumbHorizontal={renderThumbHorizontal}
//                       renderThumbVertical={renderThumb}
//                       renderView={renderView}
//                       renderTrackVertical={renderTrackVertical}
//                     >
//                       <MenueItems topMenu={topMenu} toggleCollapsed={toggleCollapsedMobile} />
//                     </Scrollbars>
//                   </Sider>
//                 </ThemeProvider>
//               ) : null}
//               <Layout className="atbd-main-layout">
//                 <Content>
//                   <WrappedComponent {...this.props} />
//                   <Typography className="text-center mb-4">Design and Develop by Papaya Coders</Typography>
//                 </Content>
//               </Layout>
//             </Layout>
//           </Layout>
//           {window.innerWidth <= 991 ? (
//             <span className={collapsed ? 'hexadash-shade' : 'hexadash-shade show'} onClick={toggleCollapsed} />
//           ) : (
//             ''
//           )}
//         </LayoutContainer>
//       );
//     }
//   }

//   const mapStateToProps = (state) => {
//     return {
//       layoutMode: state.ChangeLayoutMode.mode,
//       rtl: state.ChangeLayoutMode.rtlData,
//       topMenu: state.ChangeLayoutMode.topMenu,
//     };
//   };

//   LayoutComponent.propTypes = {
//     layoutMode: propTypes.string,
//     rtl: propTypes.bool,
//     topMenu: propTypes.bool,
//   };

//   return connect(mapStateToProps)(LayoutComponent);
// };
// export default ThemeLayout;








import React, { useState, useEffect, useCallback } from 'react';
import { Button, Col, Layout, Row, Spin, Typography } from 'antd';
import propTypes from 'prop-types';
import { Scrollbars } from '@pezhmanparsaee/react-custom-scrollbars';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import MenueItems from './MenueItems';
import CustomizerWrap from './overview/Customizer';
import { FooterStyle, LayoutContainer, SmallScreenAuthInfo, TopMenuSearch } from './Style';
import TopMenu from './TopMenu';
import Search from '../components/utilities/auth-info/Search';
import AuthInfo from '../components/utilities/auth-info/info';
import { ReactComponent as MySVG } from '../static/img/icon/left-bar.svg';
import logo from '../static/img/logo-3.png';
import { UilRefresh } from '@iconscout/react-unicons';

const { theme } = require('../config/theme/themeVariables');

const { Header, Sider, Content } = Layout;

const ThemeLayout = (WrappedComponent) => {
  const LayoutComponent = (props) => {
    const [collapsed, setCollapsed] = useState(window.innerWidth <= 1200);
    const [hide, setHide] = useState(true);
    const [walletBalance, setWalletBalance] = useState(0);
    const [reloadBalance, setReloadBalance] = useState(false);
    const [loading, setLoading] = useState(false);

    const layoutMode = useSelector((state) => state.ChangeLayoutMode.mode);
    const rtl = useSelector((state) => state.ChangeLayoutMode.rtlData);
    const topMenu = useSelector((state) => state.ChangeLayoutMode.topMenu);

    const updateDimensions = useCallback(() => {
      setCollapsed(window.innerWidth <= 1200);
    }, []);

    useEffect(() => {
      window.addEventListener('resize', updateDimensions);
      return () => {
        window.removeEventListener('resize', updateDimensions);
      };
    }, [updateDimensions]);

    const handleWalletBalanceChange = (balance) => {
      setWalletBalance(balance);
    };

    const handleReloadBalance = () => {
      setReloadBalance((prevReloadBalance) => !prevReloadBalance);
    };

    const toggleCollapsed = () => {
      setCollapsed((prevCollapsed) => !prevCollapsed);
    };

    const toggleCollapsedMobile = () => {
      if (window.innerWidth <= 990) {
        setCollapsed((prevCollapsed) => !prevCollapsed);
      }
    };

    const onShowHide = () => {
      setHide((prevHide) => !prevHide);
    };

    const SideBarStyle = {
      margin: '63px 0 0 0',
      padding: `${!rtl ? '20px 20px 55px 0' : '20px 0 55px 20px'}`,
      overflowY: 'auto',
      height: '100vh',
      position: 'fixed',
      [!rtl ? 'left' : 'right']: 0,
      zIndex: 988,
    };

    const renderThumb = ({ style }) => {
      const thumbStyle = {
        borderRadius: 6,
        backgroundColor: '#F1F2F6',
      };
      return <div style={{ ...style, ...thumbStyle }} />;
    };

    const renderTrackVertical = () => {
      const thumbStyle = {
        position: 'absolute',
        width: '6px',
        transition: 'opacity 200ms ease 0s',
        opacity: 0,
        [rtl ? 'left' : 'right']: '0px',
        bottom: '2px',
        top: '2px',
        borderRadius: '3px',
      };
      return <div className="[&>div]:bg-regular dark:[&>div]:bg-[#32333f]" style={thumbStyle} />;
    };

    const renderView = ({ style }) => {
      const customStyle = {
        marginRight: rtl && 'auto',
        [rtl ? 'marginLeft' : 'marginRight']: '-17px',
      };
      return <div style={{ ...style, ...customStyle }} />;
    };

    renderThumb.propTypes = {
      style: propTypes.shape(propTypes.object),
    };
    renderView.propTypes = {
      style: propTypes.shape(propTypes.object),
    };

    return (
      <LayoutContainer>
        <Layout className="layout">
          <Header
            style={{
              position: 'fixed',
              width: '100%',
              top: 0,
              [!rtl ? 'left' : 'right']: 0,
            }}
            className="p-0 flex items-center justify-between bg-white dark:bg-[#1b1e2b] dark:shadow-[0_5px_20px_rgba(160,160,160,.02)] h-[72px] z-998"
          >
            <div className="flex flex-row items-center flex-1 h-full">
              <div className="rtl:ssm:pr-[15px] ltr:pr-5 rtl:pl-5 ltr:ssm:pl-[15px] ltr:ssm:pr-[15px] rtl:ssm::pl:[15px] ltr:pl-[30px] rtl:pr-[30px] xs:ltr:pl-[20px] xs:rtl:pr-[20px] min-w-[280px] ssm:min-w-[220px] xs:min-w-[170px] h-full grid align-middle dark:bg-[#323541]">
                <div className="flex items-center">
                  <Link to="/admin">
                    <img className="w-full max-w-[60px] xs:max-w-[60px]" src={logo} alt="" />
                  </Link>
                  {!topMenu || window.innerWidth <= 991 ? (
                    <Button
                      type="link"
                      className="p-0 bg-transparent ml-24 border-none dark:border-transparent dark:bg-transparent dark:text-white60 dark:hover:text-primary text-[#525768] hover:text-primary"
                      onClick={toggleCollapsed}
                    >
                      <MySVG />
                    </Button>
                  ) : null}
                </div>
              </div>
              <div className="flex items-center justify-end flex-auto ltr:mr-[10px] rtl:ml-[10px] [&>div:first-child]:flex [&>div]:items-center md:flex">
                <div className="flex flex-row items-center md:hidden">
                  {topMenu && window.innerWidth > 991 ? (
                    <div className="flex top-right-wrap">
                      <AuthInfo
                        onWalletBalanceChange={handleWalletBalanceChange}
                        reloadBalance={reloadBalance}
                        setLoading={setLoading}
                        loading={loading}
                        handleReloadBalance={handleReloadBalance}
                      />
                    </div>
                  ) : (
                    <>
                      <div className='mr-2 md:hidden block'>
                      {loading ? <Spin size="small" /> : <UilRefresh onClick={handleReloadBalance} className="h-4 w-4 text-primary cursor-pointer" />}
                      </div>
                      <span className="md:hidden flex">
                        Wallet Balance: {walletBalance}Rs.
                      </span>
                      <AuthInfo
                        onWalletBalanceChange={handleWalletBalanceChange}
                        reloadBalance={reloadBalance}
                        setLoading={setLoading}
                        loading={loading}
                        handleReloadBalance={handleReloadBalance}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          </Header>
          <Row>
            <Col md={0} sm={24} xs={24}>
              <SmallScreenAuthInfo hide={hide}>
                <AuthInfo rtl={rtl} />
              </SmallScreenAuthInfo>
            </Col>
          </Row>
          <Layout>
            {!topMenu || window.innerWidth <= 991 ? (
              <ThemeProvider theme={theme}>
                <Sider
                  width={230}
                  style={SideBarStyle}
                  collapsed={collapsed}
                  theme={layoutMode === 'lightMode' ? 'light' : 'dark'}
                >
                  <Scrollbars
                    className="custom-scrollbar"
                    autoHide
                    autoHideTimeout={500}
                    autoHideDuration={200}
                    renderThumbVertical={renderThumb}
                    renderView={renderView}
                    renderTrackVertical={renderTrackVertical}
                  >
                    <MenueItems topMenu={topMenu} toggleCollapsed={toggleCollapsedMobile} />
                  </Scrollbars>
                </Sider>
              </ThemeProvider>
            ) : null}
            <Layout className="atbd-main-layout">
              <Content>
                <WrappedComponent {...props} />
                <Typography className="text-center mb-4">Design and Develop by Papaya Coders</Typography>
              </Content>
            </Layout>
          </Layout>
        </Layout>
        {window.innerWidth <= 991 ? (
          <span className={collapsed ? 'hexadash-shade' : 'hexadash-shade show'} onClick={toggleCollapsed} />
        ) : (
          ''
        )}
      </LayoutContainer>
    );
  };

  LayoutComponent.propTypes = {
    layoutMode: propTypes.string,
    rtl: propTypes.bool,
    topMenu: propTypes.bool,
  };

  return LayoutComponent;
};

export default ThemeLayout;
