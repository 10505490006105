import React, { useEffect, useState } from 'react';
import { Provider, useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import store from './redux/store';
import Admin from './routes/admin';
import Auth from './routes/auth';
import './static/css/style.css';
import config from './config/config';
import ProtectedRoute from './components/utilities/protectedRoute';
import 'antd/dist/antd.less';
import NotFound from './container/pages/404';
import Cookies from 'js-cookie';
import axios from 'axios';
import { BASE_URL } from './config';

const { theme } = config;

function ProviderConfig() {
  const { rtl, isLoggedIn, topMenu, mainContent } = useSelector((state) => {
    return {
      rtl: state.ChangeLayoutMode.rtlData,
      topMenu: state.ChangeLayoutMode.topMenu,
      mainContent: state.ChangeLayoutMode.mode,
      isLoggedIn: state.auth.login,
    };
  });

  const [lastActivityTime, setLastActivityTime] = useState(Date.now());
  const [isUserActive, setIsUserActive] = useState(true);

  useEffect(() => {
    const handleActivity = () => {
      setLastActivityTime(Date.now());
      setIsUserActive(true);
    };

    const interval = setInterval(() => {
      const currentTime = Date.now();
      if (currentTime - lastActivityTime > 30 * 60 * 1000) {
        setIsUserActive(false);
      }
    }, 60 * 1000); // Check every minute for activity

    window.addEventListener('mousemove', handleActivity);
    window.addEventListener('keydown', handleActivity);

    return () => {
      clearInterval(interval);
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('keydown', handleActivity);
    };
  }, [lastActivityTime]);

  useEffect(() => {
    // if (process.env.NODE_ENV === 'production' && !isUserActive) {
    if (!isUserActive) {
      localStorage.clear();
      Cookies.remove('access_token');
      Cookies.remove('logedIn');
      window.location.replace('/');
    }
  }, [isUserActive]);

  return (
    <ConfigProvider direction={rtl ? 'rtl' : 'ltr'}>
      <ThemeProvider theme={{ ...theme, rtl, topMenu, mainContent }}>
        <Router basename={process.env.PUBLIC_URL}>
          {!isLoggedIn ? (
            <Routes>
              <Route path="/*" element={<Auth />} />
            </Routes>
          ) : (
            <Routes>
              <Route path="/admin/*" element={<ProtectedRoute path="/*" Component={Admin} />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          )}
          {isLoggedIn &&
            (window.location.pathname === process.env.PUBLIC_URL ||
              window.location.pathname === `${process.env.PUBLIC_URL}/`) && (
              <Routes>
                <Route path="/" element={<Navigate to="/admin" />} />
              </Routes>
            )}
        </Router>
      </ThemeProvider>
    </ConfigProvider>
  );
}

function App() {
  return (
    <Provider store={store}>
      <ProviderConfig />
    </Provider>
  );
}

export default App;
